import React from "react";
import { graphql } from 'gatsby'
import { Container, Section } from "../components/Markup";
import { TitleMd, Paragraph } from "../components/Typography";
import FootnotesBlock from "../components/Footnotes"


const TexturaAboutPage = (props) => {

    const { title, about, footnotes } = props.data.queried_data
  
    return (
      <Container>
        <Section pt="lg" pb="lg">
          <TitleMd>{ title }</TitleMd>
          <Paragraph mt="md" mb="md" dangerouslySetInnerHTML={{ __html: about }}/>
          <FootnotesBlock footnotes={footnotes}/>
        </Section>
      </Container>
    );
  };

export default TexturaAboutPage;

export const query = graphql`
  query AboutTexturaQuery {
    queried_data: datoCmsAboutTextura {
      id
      title
      about
      footnotes
    }
  }
`;